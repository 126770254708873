html {
  color-scheme: light dark;
  font-family: system-ui;
  height: 100%;
  background-image: linear-gradient(to bottom, #000000, #636363 100%);
}

body {
  margin: 0;
}

h1 {
  font-size: 32pt;
}

input[type="checkbox"] {
  transform: scale(1.5);
  margin: 10px;
}

li {
  font-size: 16pt;
}