html {
  color-scheme: light dark;
  background-image: linear-gradient(#000, #636363 100%);
  height: 100%;
  font-family: system-ui;
}

body {
  margin: 0;
}

h1 {
  font-size: 32pt;
}

input[type="checkbox"] {
  margin: 10px;
  transform: scale(1.5);
}

li {
  font-size: 16pt;
}
/*# sourceMappingURL=thesandwich.c188a805.css.map */
